























































import { defineComponent } from "@vue/composition-api";
import { mapActions as mapPiniaActions } from "pinia";
import { mapActions } from "vuex";
import { logger } from "@/logger";
import { useAuthStore } from "@/pinia/auth";

interface State {
	loading: boolean;
	username: string;
	password: string;
	error?: string;
}

export default defineComponent({
	data(): State {
		return {
			loading: false,
			username: "",
			password: "",
			error: undefined,
		};
	},
	async created() {
		await this.initAuth();
	},
	async mounted() {
		await this.$nextTick();

		this.closeModal();
	},
	methods: {
		...mapPiniaActions(useAuthStore, { initAuth: "init", signIn: "signIn" }),
		...mapActions("modal", ["closeModal"]),
		async submit() {
			this.loading = true;
			this.error = undefined;

			try {
				await this.signIn({
					username: this.username,
					password: this.password,
				});

				const { callbackUrl } = this.$router.currentRoute.query;

				if (callbackUrl && typeof callbackUrl === "string") this.$router.push(callbackUrl);
				else this.$router.push({ name: "Home" });

				this.username = "";
				this.password = "";
			} catch (error) {
				switch (true) {
					case typeof error === "string": {
						this.error = error as string;

						break;
					}

					default: {
						this.error = "Er is een onbekende fout opgetreden.";
						logger.error(error);

						break;
					}
				}
			} finally {
				this.loading = false;
			}
		},
	},
});
